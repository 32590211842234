import React, { useState } from "react";
import tsiImg from "./../../Assets/tsiLogo.png";
import {
  authenticateUser
} from "./../../Services/Connection.service";
import Cookies from "js-cookie";
import "./Login.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const Login = ({ handleLogin }) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  //   const { download, url } = state; // Read values passed on state
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const loginData = {
      username: username,
      password: password,
    };

    try {
      let resp = await authenticateUser(loginData);
      const responseData = resp.data.data;
      Cookies.set("authToken", responseData.authToken, { expires: 1 });
      localStorage.setItem("authToken", responseData.authToken);
      localStorage.setItem("role", responseData.userDetails.role);
      localStorage.setItem("customer", responseData.userDetails.customer);
      localStorage.setItem("user", responseData.userDetails.username);
      if (resp.data.status === 200) {
        console.log("*** Logged In ***", responseData);
        if (state && state.download) navigate(state.url);
        handleLogin();
      } else if (resp.data.status === 500) {
        throw new Error("Login unsuccessful");
      }
    } catch (error) {
      setErrorMsg(true);
      console.log("Error:", error.message);
    }
  };

  //   return (
  //     <div>
  //       <h2>Login Page</h2>
  //       <form onSubmit={handleFormSubmit}>
  //         <input
  //           type="text"
  //           placeholder="Username"
  //           value={username}
  //           onChange={(e) => setUsername(e.target.value)}
  //         />
  //         <br />
  //         <input
  //           type="password"
  //           placeholder="Password"
  //           value={password}
  //           onChange={(e) => setPassword(e.target.value)}
  //         />
  //         <br />
  //         <button type="submit">Login</button>
  //       </form>
  //     </div>
  //   );

  return (
    <div className="login-container">
      <div className="login-header-div">
        <img className="tsilogo" alt="" src={tsiImg}></img>
        <h2 className="login-header">Login Page</h2>
      </div>
      <form onSubmit={handleFormSubmit}>
        <div className="form-group">
          <label className="userLabel">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="form-control-login"
          />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-control-login"
          />
        </div>
        {errorMsg && (
          <div style={{ marginBottom: 5 }}>
            <div
              className="error-div outerContainer d-flex justify-content-center"
              style={{ borderRadius: 10 }}
            >
              <h4 className="error-tag">Incorrect username or password</h4>
            </div>
          </div>
        )}

        <button type="submit" className="login-btn">
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
