import axios from "axios";
import Config from "./../Config/Config";

const baseUrl = `${Config.serverProtocol}://${Config.ConfigIpServer}/api/v1/`;

const userUrl = baseUrl + "user/";
const fileUrl = baseUrl + "files/";

export const authenticateUser = async (details) => {
  return axios.post(userUrl + "login", details);
};

export const getFiles = async (customer, token) => {
  const options = {
    headers: { authToken: token },
  };

  return axios.get(fileUrl + customer + "/getFiles", options);
};

export const uploadFile = (customer, fileInfo, token) => {
  const options = {
    headers: { authToken: token },
  };

  return axios.post(
    fileUrl + `uploadFile?customer=${customer}`,
    fileInfo,
    options
  );
};

export const deleteFile = (fileInfo, token) => {
  const options = {
    headers: { authToken: token },
  };

  return axios.post(fileUrl + "deleteFile", fileInfo, options);
};
// export const authenticateUser = (details) => {
//     console.log(`userUrl`, userUrl)
//     axios.post(userUrl + "login", details).then((responseData) => {
//         return responseData;
//     }).catch((error) => {
//         console.log(`error`, error)
//         alert(error)
//     });
// }

// getFiles(customer, token) {

//     const options = {
//         headers: { authToken: token }
//     };

//     axios.get(fileUrl + customer + "getfiles", options)
//         .then((responseData) => {
//             return responseData;
//         }).catch((error) => {
//             alert(error)
//         })
// }

// uploadFile(fileInfo, token) {
//     const options = {
//         headers: { authToken: token }
//     };

//     axios.post(fileUrl + "uploadFile", fileInfo, options)
//         .then((responseData) => {
//             return responseData;
//         })
//         .catch((error) => {
//             alert(error)
//         });
// }
