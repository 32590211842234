import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import userIcon from "./../../Assets/turn-off-turn-on-power-off-on-shut-down-svgrepo-com.svg";
import tsiImg from "./../../Assets/tsiLogo.png";
import './Navbar.css';

const Navbar = ({ handleLogout }) => {

    const user = localStorage.getItem('user');

    const handleLogoutClick = () => {
        // Perform logout logic
        localStorage.clear();
        console.log(`Logging Out User.........................`)
        handleLogout();
    };

    return (

        <nav className="navbar">
            <a className="navbar-logo" href="/">
                <img src={tsiImg} alt="TSI Logo" className="logo-image" />
                <span className="logo-text">TPF Software </span>
            </a>
            <div className="nav-menu">
                <div className="nav-item">
                    <span className='username'>Hello {user}</span>
                </div>
                <div className="nav-item">
                    <img src={userIcon} alt="Logout" onClick={handleLogoutClick} className="logoff-image" />
                </div>
            </div>
        </nav>

    )

};

export default Navbar;
