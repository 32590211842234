import React, { useState, useEffect, Link } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "./Table.css";
import { makeStyles } from "@mui/styles";
import downloadBtn from "./../../Assets/file-download-svgrepo-com.svg";
import copyBtn from "./../../Assets/copy-document-svgrepo-com.svg";
import Config from "./../../Config/Config";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiCopy } from "react-icons/bi";
import { FiDownload } from "react-icons/fi";

import {
  authenticateUser,
  deleteFile,
  getFiles,
} from "./../../Services/Connection.service";
import Alert from "../Alert/Alert";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { Toast } from "../../Config/Toast";
import Swal from "sweetalert2";

const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: "blue",
    color: "white",
  },
});

const Table = ({ handleLogout, refreshValue, onValues, refresh }) => {
  //TODO Url Config
  const navigate = useNavigate();

  // const [refreshValue, setRefreshValue] = useState(refreshTable);
  const fileUrl = `${Config.serverProtocol}://${Config.ConfigIpServer}/api/v1/files/`;
  const [filterBlur, setFilterBlur] = useState("blur(0px)");
  const [pointerEve, setPointerEve] = useState("all");
  const [showPopUp, setShowPopup] = useState(false);
  const token = localStorage.getItem("authToken");
  const customer = localStorage.getItem("customer");
  const [showBreachError, setShowBreach] = useState(false);
  const [showView, setShowView] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const classes = useStyles();
  const [errorValue, setErrorValue] = useState({});

  const customLocaleText = {
    noRowsLabel: "No build have been found",
  };

  useEffect(() => {
    setColumns([]);
    setTable(onValues.rows);
  }, [onValues]);

  const handleCopy = (value) => {
    // console.log("value-->", value);
    const textArea = document.createElement("textarea");
    textArea.value = `${Config.clientProtocol}://${Config.ConfigIpServer}/download/${value.fileName}/${token}`;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
      Toast.fire({ title: "File Copied!", icon: "success", timer: 1000 });
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
    document.body.removeChild(textArea);
    // navigator.clipboard
    //   .writeText(
    //     `rgew`
    //   )
    //   .then(() => {
    //     console.log("Text copied to clipboard:", "copiedText");
    //   })
    //   .catch((error) => {
    //     console.error("Failed to copy text:", error);
    //   });
  };

  const handleSessionClose = () => {
    localStorage.clear();
    handleLogout();
  };
  const isAdmin =
    localStorage.getItem("role") === "admin" &&
    localStorage.getItem("customer") === "TSI";

  const tsiCustomer = localStorage.getItem("customer") === "TSI";
  const handleDeleteFile = async (data) => {
    Swal.fire({
      title: "Confirmation",
      text: "Are you sure that you want to delete this file?",
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Proceed",
      showCloseButton: true,
      confirmButtonColor: "#1fc6f5",
      icon: "info",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteFile(data, token);
          if (response.status === 200) {
            refresh();
            Toast.fire({ title: "File Deleted!", icon: "success" });
          }
        } catch (e) {
          console.log(e);
          Toast.fire({ title: "Something went wrong!", icon: "error" });
        }
      } else if (result.isDenied) {
        console.log("Deletion denied!");
      }
    });
  };

  const setTable = (onValues) => {
    setColumns([
      { field: "id", headerName: "ID", width: 60, textAlign: "center" },
      { field: "fileNameDisplay", headerName: "File name", width: 230 },
      { field: "component", headerName: "Component", width: 200 },
      // TODO to show the file uploader
      // { field: "username", headerName: "Uploaded By", width: 150 },

      { field: "comments", headerName: "Comments", width: 200 },
      {
        field: "modifiedDate",
        headerName: "Modified Date",
        width: 200,
        valueFormatter: (params) => new Date(params.value).toLocaleString(),
      },
      {
        field: "downloadLink",
        headerName: "Download",
        description: "This column has a value getter and is not sortable.",
        sortable: false,
        width: 100,
        renderCell: (params) => (
          <a
            href={`${fileUrl + params.row.downloadLink}/${token}`}
            style={{ textAlign: "left", width: "100%" }}
            // target="_blank"
            rel="noopener noreferrer"
          >
            {/* <img
              src={downloadBtn}
              alt="Unable to Fetch Link"
              style={{ height: "20%", width: "20%" }}
            /> */}
            <FiDownload
              color={"#158c03"}
              style={{ height: "30%", width: "30%" }}
            />
            {/* <p>{fileUrl + params.row.downloadLink}</p> */}
          </a>
        ),
      },
      // TODO:Copy Feature
      // isAdmin || tsiCustomer
      //   ? {
      //       field: "Copy",
      //       headerName: "Copy",
      //       sortable: false,
      //       width: 100,
      //       renderCell: (params) => (
      //         <a
      //           onClick={() => handleCopy(params.row)}
      //           style={{
      //             textAlign: "left",
      //             cursor: "pointer",
      //             width: "100%",
      //           }}
      //         >
      //           {/* <img
      //             src={copyBtn}
      //             alt="Unable to Fetch Link"
      //             style={{ height: "50%", width: "50%" }}
      //           /> */}
      //           <BiCopy
      //             color={"#02c9e3"}
      //             style={{ height: "30%", width: "30%" }}
      //           />
      //         </a>
      //       ),
      //     }
      //   : "",
      isAdmin
        ? {
            field: "Delete",
            headerName: "Delete",
            sortable: false,
            width: 100,
            renderCell: (params) => (
              <a
                onClick={() => handleDeleteFile(params.row)}
                style={{
                  textAlign: "left",
                  cursor: "pointer",
                  width: "100%",
                }}
              >
                {/* <img
                  src={copyBtn}
                  alt="Unable to Fetch Link"
                  style={{ height: "50%", width: "50%" }}
                /> */}
                <RiDeleteBin6Line
                  color={"red"}
                  style={{ height: "30%", width: "30%" }}
                />
              </a>
            ),
          }
        : "",
    ]);
    setRows(onValues);
  };

  return (
    <div className="table-container">
      <div className=" rounded-2" style={{ width: "100%", minHeight: "50px" }}>
        {showBreachError && (
          <div style={{ textAlign: "center" }}>
            <Alert
              closeSession={handleSessionClose}
              value="Security Breach,Not authorized"
            />
          </div>
        )}
        <Box sx={{ height: "65vh", width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            rowHeight={32}
            disableRowSelectionOnClick
            localeText={customLocaleText}
            // loading={rows.length === 0}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            style={{ textAlign: "center" }}
            pageSizeOptions={[10, 20]}
          />
        </Box>
      </div>
    </div>
  );
};

export default Table;
