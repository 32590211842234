import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
import er from "./../../Assets/copy-document-svgrepo-com.svg";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Alert from "../Alert/Alert";
import Config from "./../../Config/Config";

const DownloadFile = () => {
  const { customer, file,token } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Waiting for File to Download"
  );
  const [navPath, setNavPath] = useState("/");

  useEffect(() => {
    downloadFile();
  }, []);

  const downloadFile = async () => {
    try {
      if (localStorage.getItem("customer")) {
        const authToken = localStorage.getItem("authToken");
        // const user = localStorage.getItem("customer").toLocaleLowerCase();
        // const role = localStorage.getItem("role").toLocaleLowerCase();

        const options = {
          headers: { authToken: authToken },
        };
        let checkUrl = `${Config.serverProtocol}://${Config.ConfigIpServer}/api/v1/files/buildFiles/authCheck/${customer}/${file}/${token}`;
        axios.get(checkUrl, options).then((response) => {
          if (!response.data.error) {
            let url = `${Config.serverProtocol}://${Config.ConfigIpServer}/api/v1/files/buildFiles/${customer}/${file}/${token}`;
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `FileName.pdf`);
            // Append to html link element page
            document.body.appendChild(link);
            setErrorMessage("File downloaded!");
            // Start download
            link.click();
            link.parentNode.removeChild(link);
            setTimeout(() => {
              navigate("/home");
            }, 3000);
          } else {
            if (response.data.message === "Security Breach")
              navigate("/invalidUser");
            else if (response.data.message === "File not found")
              setErrorMessage(
                response.data.message + " Click on OK to go to Home Page"
              );
            else
              setErrorMessage(
                response.data.message + " Click on OK to go to Home Page"
              );
            setError(true);
            // setNavPath("/");
          }
        });
        // let url = `${Config.serverProtocol}://${Config.configIpServer}/api/v1/files/buildFiles/${customer}/${file}`;
        // axios.get(url, options).then((response) => {
        //   if (!response.data.error) {
        //     window.location.href = url;
        //     setNavPath("/home");
        //     setErrorMessage(`The ${file} have been downloaded`);
        //     setTimeout(() => {
        //       navigate("/home");
        //     }, 3000);
        //   } else {
        //     if (response.data.message === "Security Breach")
        //       setErrorMessage(
        //         `You are not Authorized to download the  ${file} file, Click on OK to go to Home Page`
        //       );
        //     else if (response.data.message === "File not found")
        //       setErrorMessage(
        //         response.data.message + " Click on OK to go to Home Page"
        //       );
        //     else
        //       setErrorMessage(
        //         response.data.message + " Click on OK to go to Home Page"
        //       );
        //     setError(true);
        //     setNavPath("/");
        //   }
        // });
      } else {
        setErrorMessage(
          "You are not logged-in to your account, Click on OK to go to login page"
        );
        setError(true);
      }
    } catch (error) {
      console.log("ERROR -->", error);
      setError(true);
    }
  };

  return (
    <div className="outerContainer d-flex justify-content-center">
      {/* Your download page content */}
      {/* <div class=" container rounded card m-auto m-5">
        <div class="card-body text-center">
          {error ? <Link style={{ color: 'red' }} to="/">{errorMessage}</Link> : <div>Loading..</div>}

        </div>
      </div> */}
      <Alert
        errorMessage={{
          header: "Download Status",
          Message: errorMessage,
          path: navPath,
        }}
        url={`/download/${customer}/${file}`}
      />
    </div>
  );
};

export default DownloadFile;
