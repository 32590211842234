import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../Navbar/Navbar";
import Table from "../Table/Table";
import Upload from "../Upload/Upload";
import Alert from "../Alert/Alert";
import "./Home.css";
import conf from "./../../Config/Config";
import DeleteIcon from "@mui/icons-material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Select as MuiSelect } from "@mui/material";
import add from "./../../Assets/add.png";
import { useParams, useNavigate } from "react-router-dom";

import {
  authenticateUser,
  getFiles,
  uploadFile,
} from "./../../Services/Connection.service";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const Home = ({ handleLogout, loggedIn }) => {
  // console.log("Logout=============>", loggedIn);
  const navigate = useNavigate();

  // if (!localStorage.getItem("customer")) {
  //   navigate('/')
  // }
  const isAdmin = localStorage.getItem("role") === "admin" ? true : false;
  const isCustomer = localStorage.getItem("customer") !== "TSI" ? true : false;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  // const role = localStorage.getItem('role') ==='admin';
  // const Check = "ADMIN" === "ADMIN"
  // const customer = localStorage.getItem('customer')
  const [showPopUp, setShowPopup] = useState(false);
  const [showView, setShowView] = useState(false);
  const [filterBlur, setFilterBlur] = useState("blur(0px)");
  const [pointerEve, setPointerEve] = useState("all");
  const [showTimeOut, setShowTimeOut] = useState(false);
  const [showBreachError, setShowBreach] = useState(false);
  const [refreshValue, setRefreshValue] = useState(false);
  const token = localStorage.getItem("authToken");
  const [listOfCustomer, setlistOfCustomer] = React.useState(
    localStorage.getItem("customer")
  );
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  const handleSessionClose = () => {
    // Cookies.remove('authToken')
    localStorage.clear();
    handleLogout();
    navigate("/");
  };

  const handleClose = () => {
    // console.log("handleClose");
    setRefreshValue(!refreshValue);
    setShowPopup(false);
    setFilterBlur("blur(0px)");
    setPointerEve("all");
    setShowPopup(false);
    getFileDetails();
  };

  const blurDiv = () => {
    // console.log("blur div called");
    setFilterBlur("blur(4px)");
    setPointerEve("none");
  };

  function modalBox(header, body) {
    return (
      <div>
        <Modal
          open={true}
          onClose={() => {
            handleSessionClose();
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          animationType="slide"
          transparent={true}
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Time out !
            </Typography>
            <Typography
              style={{ textAlign: "center" }}
              id="modal-modal-description"
              sx={{ mt: 2 }}
            >
              Session has been expired.
              <button
                className="upload-home-btn"
                onClick={() => {
                  handleSessionClose();
                }}
              >
                OK
              </button>
            </Typography>
          </Box>
        </Modal>
      </div>
    );
  }

  const handleButtonClick = () => {
    blurDiv();
    setShowPopup(true);
  };

  const handleChange = (event) => {
    setlistOfCustomer(event.target.value);
  };

  const getFileDetails = () => {
    getFiles(listOfCustomer, token)
      .then((response) => {
        // console.log("response.status =============>", response.data);
        setLoading(false);

        if (response.data.status === 200) {
          // console.log("files =======>", response.data);
          setRows([...response.data.files]);
        } else if (response.data.error) {
          if (response.data.message === "Security Breach") {
            setShowBreach(true);
          } else {
            // console.log("-----");
            // alert(response.data.message)
            // if(response.data.message.includes('Invalid Authorization Key')){
            setShowTimeOut(true);
            // }
          }
        }
      })
      .catch((error) => {
        if (error.response.data.error === "Error reading directory") {
          // alert(error.response.data.error);
        } else {
          setShowTimeOut(true);
          console.error(
            "Error in Fetching File Details:",
            error.response.data.error
          );
        }
      });
  };

  useEffect(() => {
    getFileDetails();
    // console.log("listOfCustomer-->", listOfCustomer);
  }, [listOfCustomer]);

  return (
    <div className="page-body">
      {loggedIn && <Navbar handleLogout={handleLogout} />}
      {showPopUp && (
        <div className="pop-up-div">
          <Upload onClose={handleClose} />
        </div>
      )}
      <div className="containe-fluid">
        <div
          className="content"
          style={{ filter: filterBlur, pointerEvents: pointerEve }}
        >
          {loading ? (
            <div className="centerContainer d-flex justify-content-center">
              <div className="spinner-border m-auto text-primary" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          ) : (
            <div
              style={{ width: "90%" }}
              className=" centerContainer d-flex justify-content-center m-auto"
            >
              {showBreachError && (
                <div>
                  {modalBox(
                    "Authorizion Issues",
                    "Security Breach, Not authorized"
                  )}
                </div>
              )}
              {showTimeOut && (
                <div>
                  {modalBox("Time out !", " Session has been over login back")}
                </div>
              )}

              <div style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: 8,
                    marginTop: 20,
                  }}
                >
                  {isAdmin || isCustomer ? (
                    <div
                      style={{
                        textAlign: "left",
                        alignItems: "end",
                        marginTop: 20,
                      }}
                    >
                      <button
                        className="upload-home-btn"
                        onClick={handleButtonClick}
                      >
                        <span style={{ fontSize: 18 }}>+</span> Upload
                      </button>
                    </div>
                  ) : (
                    <div></div>
                  )}

                  {!isCustomer ? (
                    <div style={{ textAlign: "right", alignItems: "end" }}>
                      <FormControl
                        variant="standard"
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Customer
                        </InputLabel>
                        <MuiSelect
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={listOfCustomer}
                          onChange={handleChange}
                          label={listOfCustomer}
                        >
                          {conf.customerValues.map((value) => {
                            return (
                              <MenuItem key={value} value={value}>
                                {value}
                              </MenuItem>
                            );
                          })}
                        </MuiSelect>
                      </FormControl>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>

                {loggedIn && (
                  <Table
                    handleLogout={handleLogout}
                    refreshValue={refreshValue}
                    refresh={getFileDetails}
                    onValues={{ rows: rows, currentCustonmer: listOfCustomer }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
