const config = {
  customerValues: [
    "Air NZ",
    "Aer Lingus",
    "Amex",
    "BA",
    "Dell",
    "Delta",
    "Dev",
    "EMC",
    "Galileo",
    "Marriott",
    "Sabre",
    "TSI",
    "Travelport",
    "Turkish Airlines",
    "VISA",
  ],
  componentValues: ["Host", "PC", "Middleware","Document Suite", "OS"],
  /*
  ServerIP/URL if local localhost:8217 else, tpfsofware.net no port needed.
 */
  //ConfigIpServer: "localhost:8217",
  ConfigIpServer: "tpfsoftware.net",
  /**
  Client http protocol if prod https else http 
 */
  //clientProtocol: "http",
  clientProtocol: "https",
  /** 
  Server http protocol, both prod and preprod will have the https protocol.
 */
  serverProtocol: "https",
};

export default config;
