import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";
import "./App.css";
import Login from "./Components/Login/Login";
import Home from "./Components/Home/Home";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import DownloadFile from "./Components/Download/Download";
import InvalidUser from "./Components/InvalidUser/InvalidUser";

const App = () => {
  const checkLogin = localStorage.getItem("authToken");
  const [loggedIn, setLoggedIn] = useState(checkLogin == null ? false : true);

  const handleLogin = () => {
    setLoggedIn(true);
  };

  const handleLogout = () => {
    setLoggedIn(false);
  };

  // Checks LoggedIn Status
  useEffect(() => {
    const handleStorageChange = () => {
      if (loggedIn) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={
            loggedIn ? (
              <Navigate to="/home" />
            ) : (
              <Login handleLogin={handleLogin} loggedIn={loggedIn} />
            )
          }
        />
        <Route
          exact
          path="/home"
          element={
            loggedIn ? (
              <Home handleLogout={handleLogout} loggedIn={loggedIn} />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          exact
          path="/download/:customer/:file/:token"
          element={<DownloadFile />}
        />
         <Route
          exact
          path="/invalidUser"
          element={<InvalidUser handleLogout={handleLogout} />}
        />
      </Routes>
    </Router>
  );
};

export default App;
