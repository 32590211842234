import React, { useState } from "react";
import "./Alert.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useParams, useNavigate } from "react-router-dom";

export default function Alert({ closeSession, errorMessage, url }) {
  const navigate = useNavigate();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    // border: '1px solid #000',
    // boxShadow: 10,
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    borderRadius: "10px",
    alignItems:'center',
    p: 4,
  };

  const handleSessionClose = () => {
    if (errorMessage.path === "/") {
      // localStorage.clear();
      // closeSession
    }
    navigate(errorMessage.path, { state: { download: true, url: url } });
  };

  console.log("closeSession", closeSession, "errorMessage", errorMessage);
  function modalBox(header, body) {
    return (
      <div>
        <Modal
          open={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {errorMessage.header}
            </Typography>
            <Typography
              style={{ textAlign: "center" }}
              id="modal-modal-description"
              sx={{ mt: 2 }}
            >
              {errorMessage.Message}
            </Typography>
            <br/>
            <button
              className="upload-home-btn"
              onClick={() => {
                handleSessionClose();
              }}
            >
              OK
            </button>
          </Box>
        </Modal>
      </div>
    );
  }

  return <div>{modalBox("Error", errorMessage)}</div>;
}
