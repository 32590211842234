import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import Config from "./../../Config/Config";
import Select from "react-select";
import "./Upload.css";
import closeIcon from "./../../Assets/x-close-delete-svgrepo-com.svg";
import { ClipLoader } from "react-spinners";
import Alert from "../Alert/Alert";
import { useNavigate } from "react-router-dom";
import {
  uploadFile,
} from "./../../Services/Connection.service";
import Cookies from "js-cookie";

const Upload = ({ onClose }) => {
  const isCustomer = localStorage.getItem("customer") !== "TSI" ? true : false;
  let [loading, setLoading] = useState(false);
  const token = localStorage.getItem("authToken");
  const [showTimeOut, setShowTimeOut] = useState(false);
  const custValues = Config.customerValues;
  const compValue = Config.componentValues;
  const [customerValue, setCustomerValue] = useState(null);
  const [customerDrop, setCustomerDrop] = useState([]);
  const [componentValue, setCompValue] = useState(null);
  const [commentValue, setCommentValue] = useState("");
  const [compDrop, setCompDrop] = useState([]);
  const [showMsg, setShowMsg] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [navPath, setNavPath] = useState("/");

  const [showMandatField, setShowMandateField] = useState(false);
  const [fileUploadMsg, setFileUploadMsg] = useState("");
  const navigate = useNavigate();
  const placeholder = customerValue !== null ? customerValue : "Customer";
  const [file, setFile] = useState("");

  const componentplaceholder =
    componentValue !== null ? componentValue : "Component";
  useEffect(() => {
    console.log("Customer Values", custValues);
    if (isCustomer) setCustomerValue(localStorage.getItem("customer"))
    let listOfCustomer = [{}];
    for (var i = 0; i < custValues.length; i++) {
      listOfCustomer[i] = {
        value: custValues[i],
        label: custValues[i],
      };
    }
    setCustomerDrop(listOfCustomer);

    let listOfComponent = [{}];
    for (var j = 0; j < compValue.length; j++) {
      listOfComponent[j] = {
        value: compValue[j],
        label: compValue[j],
      };
    }
    setCompDrop(listOfComponent);
  }, [custValues, compValue]);
  //   adding values to teh drop down in the required format for 'react-select'
  //setting the chosen value from the drop down
  const setValueForCustomer = (value) => {
    setCustomerValue(value.label);
  };
  const setValueForComponent = (value) => {
    setCompValue(value.label);
  };

  const onFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  // Upload File for Customer
  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    if (file && customerValue && commentValue && componentValue) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("customer", customerValue);
      formData.append("comment", commentValue);
      formData.append("component", componentValue);

      uploadFile(customerValue,formData, token).then((Response) => {
        console.log(Response.data);
        setFileUploadMsg(Response.data.message);

        if (Response.data.error) {
          console.log("Response===>", Response);
          setIserror(true);
          setErrorMessage(Response.data.message);
          setNavPath("/");
        } else {
          if (
            Response.data.message === "Failed To Authorized" &&
            Response.data.status === 500
          ) {
            setShowTimeOut(true);
          }
          if (Response.data.message === "File uploaded successfully") {
            setLoading(false);
            setShowMsg(true);
          }
        }
      });
    } else {
      setLoading(false);
      setShowMandateField(true);
      setTimeout(() => {
        setShowMandateField(false);
      }, 3000);
    }
  };

  const handleSessionClose = () => {
    Cookies.remove("authToken");
    localStorage.clear();
    navigate("/");
  };

  return (
    <div>
      {iserror ? (
        <Alert
          closeSession={handleSessionClose}
          errorMessage={{
            header: "Upload Status",
            Message: errorMessage,
            path: navPath,
          }}
        />
      ) : (
        <div className="form-area-upload">
          {showMsg && (
            <div className="pop-up-div">
              <div className="Alert-container-upload-file">
                <h4 className="timeout-tag">{fileUploadMsg}</h4>
                <button className="close-session-btn" onClick={onClose}>
                  Ok
                </button>
              </div>
            </div>
          )}
          {loading && (
            <div className="pop-up-div">
              <ClipLoader
                className="LoaderFade"
                loading={loading}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          )}
          {showTimeOut && (
            <Alert
              closeSession={handleSessionClose}
              value={"Session timed out"}
            />
          )}
          <div className="header-div">
            <div></div>
            {showMandatField ? (
              <h4 className="mandateTag">
                {" "}
                &nbsp;<i>*</i> Please fill out all the fields <i>*</i> &nbsp;
                &nbsp;{" "}
              </h4>
            ) : (
              ""
            )}
            <button onClick={onClose} className="close-upload-btn">
              <img
                className="close-upload"
                alt="Close Search"
                src={closeIcon}
              ></img>
            </button>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="dropDown-div">
                {isCustomer ? (
                  <input
                    type="text"
                    placeholder={placeholder}
                    value={customerValue}
                    hidden={isCustomer}
                    required
                  />
                ) : (<Select
                  options={customerDrop}
                  placeholder={placeholder}
                  value={customerValue}
                  onChange={setValueForCustomer}
                  isRequired
                ></Select>)}
              </div>
            </div>

            <div className="dropDown-div">
              <Select
                options={compDrop}
                placeholder={componentplaceholder}
                value={componentValue}
                onChange={setValueForComponent}
                isRequired
              ></Select>
            </div>
            <div className="form-group">
              <textarea
                type="text"
                rows={3}
                className="comment-text"
                placeholder="Comments"
                onChange={(e) => setCommentValue(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                type="file"
                className="form-control-upload"
                onChange={onFileChange}
              />
            </div>
            <div className="form-group text-center">
              <button type="submit" className="upload-home-btn ">
                Upload
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Upload;
