import React, { useEffect, useState } from "react";
import access from "../../Assets/access.png";
import Navbar from "../Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import "./invalidUser.css";
const InvalidUser = ({ handleLogout }) => {
  const navigate = useNavigate();
  const [listOfCustomer, setlistOfCustomer] = useState(
    localStorage.getItem("customer")
  );
  const [user, setUser] = useState(localStorage.getItem("user"));
  const [timer, setTimer] = useState(5);

  console.log(listOfCustomer);

  useEffect(() => {
    setTimeout(() => {
      navigate("/home");
    }, 5000);
    setInterval(() => {
      setTimer((pre) => parseInt(pre, 10) - 1);
    }, 1000);
  }, []);
  return (
    <>
      <Navbar handleLogout={handleLogout} />
      <div
        style={{
          width: "100%",
          height: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img src={access} />
        <h3 className="header">Hello {user}!</h3>
        <h4 className="header">{`You are trying to access an unauthorized file!`}</h4>
        <a
          href="/home"
          className="header"
        >{`Redirecting to home page in (${timer})`}</a>
      </div>
    </>
  );
};

export default InvalidUser;
